import styled from "styled-components";

const StyledLearningMethodTitle = styled.div`
  h1, h2, h3, h4, h5, h6 {
    display: inline-block;
    background-color: ${props => props.themeColour};
    color: #fafafa;
    padding: 0.4em 0.6em;
  }
`

export default StyledLearningMethodTitle