import { graphql, Link } from 'gatsby'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import SubLayout from '../../components/layouts/SubLayout'
import Seo from '../../components/Seo'
import StyledLearningMethodTitle from '../../components/styles/StyledLearningMethodTitle'
import StyledMethodsAndFieldsPage from '../../components/styles/StyledMethodsAndFieldsPage'

const StudyFieldPage = ({ data }) => {
  const {
    title,
    slug,
    description,
    featuredImage
  } = data.sanityStudyField

  const learningMethods = data.allSanityLearningMethod.nodes
  const courses = data.allSanityCourse.nodes

  const learningMethodsAndTheirCourses = []

  learningMethods.forEach(method => {
    const methodCourses = courses.filter(course => {
      const courseLearningMethods = course.courseStartDates.map(instance => instance.learningMethod.title)
      return courseLearningMethods.includes(method.title)
    })

    if (methodCourses.length <= 0) {
      return
    }

    learningMethodsAndTheirCourses.push({
      title: method.title,
      themeColour: method.themeColour,
      courses: methodCourses
    })
  })

  const coursesListByMethodElements = learningMethodsAndTheirCourses
    .map((method) => {
      return (
        <section key={method.title} className='method-container'>
          <StyledLearningMethodTitle themeColour={method.themeColour}>
            <h3>{method.title}</h3>
          </StyledLearningMethodTitle>

          <ul>
            {method.courses.map(course => (
              <li key={course.slug.current}><Link to={`/${slug.current}/${course.slug.current}/`}>{course.title}</Link></li>
            ))}
          </ul>
        </section>
      )
    })

  return (
    <SubLayout>
      <Seo title={title} pathname={`/study-fields/${slug.current}`} />
      <StyledStudyFieldPage>
        <h1>{title}</h1>

        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          <Link to='/study-fields/'>Study Fields</Link> {`>`}&nbsp;
          {title}
        </p>

        <section className="content">
          <img src={featuredImage.asset.url} alt={title} />
          <ReactMarkdown>{description}</ReactMarkdown>
        </section>

        <section className="courses">
          {coursesListByMethodElements}
        </section>
      </StyledStudyFieldPage>
    </SubLayout>
  )
}

export const query = graphql`
  query StudyFieldQuery($id: String) {
    sanityStudyField(id: { eq: $id }) {
      title
      slug { current }
      description
      featuredImage {
        asset { url }
      }
    }
    allSanityCourse(
      filter: {studyFields: {elemMatch: {id: {eq: $id}}}},
      sort: {fields: title}
    ) {
      nodes {
        title
        slug { current }
        courseStartDates {
          learningMethod {
            title
          }
        }
      }
    }
    allSanityLearningMethod(sort: {fields: rank}) {
      nodes {
        title
        themeColour
      }
    }
  }
`

const StyledStudyFieldPage = styled(StyledMethodsAndFieldsPage)`
  h1 {
    font-size: 2.2rem;
  }

  h3 {
    margin-bottom: 0.75rem;
  }

  section.method-container {
    margin-bottom: 2rem;
  }
`

export default StudyFieldPage
